import React from "react"
import Layout from "../Layout"
import { Reviews } from "../components/rings/Reviews"
import { useStaticQuery, graphql } from "gatsby"
import { Seo } from "../components/Seo"

export default function Reacties() {
  const allReviews = useStaticQuery(graphql`
    query AllReviews {
      allSanityReview {
        nodes {
          name
          stars
          _rawMessage
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo urlLoc="https://www.onehappyhound.be/reacties" />
      {allReviews && <Reviews reviews={allReviews.allSanityReview} />}
    </Layout>
  )
}
